import './i18n';

export function main() {
  if (process.env.REACT_APP_TARGET === 'manager') {
    import('./apps/manager');
  } else if (process.env.REACT_APP_TARGET === 'customer') {
    import('./apps/customer');
  } else {
    throw new Error('Target not supported: ' + process.env.REACT_APP_TARGET);
  }
}

main();
